document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

$s.behave.onload.actionRequired = function (context) {
    if (context == null) return;
    $(document).on("actionRequired", function (evt, action) {
        if (action.method == "redirect") {
            window.location.href = action.href;
        }

        if (action.method == 'authorizationRequired') {
            $s.showPopup("login");
        }
        if (action.method == 'confirmation') {
            $('.modal').modal('hide');
            var $emailMessage = $('.email-message');
            $emailMessage.empty();
            $emailMessage.html($('.send-email-container textarea[name="message"]').val());
            $s.showPopup("popupEmailConfirmation");
        }
        if (action.method == 'closeModal') {
            $('.modal').modal('hide');
        }

        if (action.method == "refresh") {
            window.location.href = window.location.href;
            history.go(0);
            window.location.reload();
        }
        if (action.method == "loginRedirect") {

            var container = $(".js-login-container");
//            var login = container.find("input[name=email]").val();
//            var password = container.find("input[name=password]").val();
//            var redirect_to = container.find("input[name=redirect_to]").val();
            var url = container.find("form").attr("action");
            var form = $('<form action="' + url + '" method="post">' +
                '<input type="text" name="email" value="' + action.email + '" />' +
                '<input type="text" name="password" value="' + action.password + '" />' +
                '<input type="text" name="redirect_to" value="' + action.redirect + '" />' +
                '<input type="text" name="facebookUID" value="' + action.facebookUID + '" />' +
                '<input type="text" name="not_ajax" value="true" />' +
                '</form>');
            form.css({"opacity":"0"});
            $('body').append(form);
            $(form).submit();
        }

        if (action.method == "updateHTML") {
            $(action.target).html(action.content);
            if (action.callback && window[action.callback]) {
                window[action.callback]();
            }
        }
        if (action.method == "callback") {
            if (action.callback && window[action.callback]) {
                window[action.callback]();
            }
        }
    });

    $("#btn_open_close_menu, .dropdown_menu > a:first-child").on("click", function () {
        var t = $('#btn_open_close_menu');

        if (t.hasClass("opened")) {
            t.removeClass("opened");
            $('.dropdown_menu_container_pos').removeClass("opened");
            return false;
        } else {
            t.addClass("opened");
            $('.dropdown_menu_container_pos').addClass("opened");
            return false;
        }
    })
};

/* Zoom photos */
$s.behave.onload.zoomPhoto = function () {
    $('.photo-main .zoom-photo').on('click', function () {
        var url = $(this).attr('data-url'),
            photoMain = $('.photo-main'),
            photoZoomed = $('.photo-zoomed');
        if ($(photoMain).hasClass('zoomed')) {
            $('.sidebar-container').show();
        } else {
            $('.sidebar-container').hide();
            photoZoomed.css('max-width', '1004px');
            photoZoomed.attr('src', url);
        }
        photoMain.toggleClass('zoomed');
        return false;
    });
};


/* Vendor's images slider and popup with slider */
$s.behave.onload.vendorImages = function () {
    $('#modal-popupVendorPhoto').each(function () {
        var $modal = $(this),
            $container = $modal.find('.popup-vendor-slider-container'),
            $containerUL = $container.find('ul'),
            $nav = $('.vendor-images .controls a'),
            $btnPrev = $nav.filter('.prev'),
            $btnNext = $nav.filter('.next'),
            $btnPrevModal = $modal.find('.prev'),
            $btnNextModal = $modal.find('.next'),
            $slides = $('.vendor-images-slides'),
            count = parseInt($slides.attr('data-count'), 10),
            columns = Math.floor(count / 9) * 3 + Math.min(count - (Math.floor(count / 9) * 9), 3),
            // columns = Math.ceil(count / 3),
            shift = 203;
            console.log('count ', count);
            console.log('columns ',columns);

        function toggleModalNavButtons(number) {
            (number > 1) ? $btnPrevModal.show() : $btnPrevModal.hide();
            (number < count) ? $btnNextModal.show() : $btnNextModal.hide();
        }

        function checkColumns(column, w) {
            var p = (w >= 768) ? 60 : 30;
            (column > 1) ? $btnPrev.show() : $btnPrev.hide();
            if (w > 1024) {
                (count - column * 3 >= 7) ? $btnNext.show() : $btnNext.hide();
            } else {
                (count - column * 3 >= Math.floor((w - p) / 147) * 3 - 2) ? $btnNext.show() : $btnNext.hide();
            }
        }

        checkColumns(1, document.body.clientWidth);
        $nav.on('click', function () {
            var w = document.body.clientWidth,
                dir = $(this).data('dir'),
                column = parseInt($slides.attr('data-column'), 10),
                diff = columns - column;

            if (w > 1024) {
                shift = 203;
                if (dir === 'next') {
                    column = (diff > 5) ? column + 3 : column + (diff - 2);
                } else {
                    column = (column >= 4) ? column - 3 : 1;
                }
            } else {
                shift = 147;
                column = column + ((dir === 'next') ? 1 : -1);
            }

            $slides.css({
                '-webkit-transform': 'translateX(' + -(column - 1) * shift + 'px)',
                'transform': 'translateX(' + -(column - 1) * shift + 'px)'
            });

            $slides.attr('data-column', column);
            checkColumns(column, w);

            return false;
        });

        function adjustPopup() {
            var w = document.body.clientWidth;
            if (w <= 1024) {
                $containerUL.find("li").css({width: w, height: window.innerHeight});
            } else {
                $containerUL.find("li").css({width: '638px', height: '500px'});
            }

            $containerUL.css('margin-left', ((w <= 1024) ? -w : -638) * (currImgNumber - 1));
            $container.data('number', currImgNumber);
        }

        var currImgNumber = 1;
        $('.vendor-image').on('click', function () {
            currImgNumber = parseInt($(this).data('number'), 10);

            toggleModalNavButtons(currImgNumber);
            adjustPopup();
            $s.showPopup('popupVendorPhoto');
            return false;
        });

        $(window).on('resize orientationchange', function () {
            adjustPopup();
        });

        $('.modal-nav').on('click', function () {
            var dir = $(this).data('dir'),
                w = document.body.clientWidth,
                number = parseInt($container.data('number'), 10);
            number = (dir == 'next') ? number + 1 : number - 1;
            toggleModalNavButtons(number);
            $container.data('number', number);
            $containerUL.css('margin-left', ((w <= 1024) ? -w : -638) * (number - 1));
            return false;
        });
    });
};

/** Inifinite loader for home page */

$s.behave.onload.inifiniteHomePage = function () {
    var homeIsLoading = false,
        maximumNewAjax = 9,
        currentAjaxCount = 0,
        lastScrollTop = window.pageYOffset,
        direction,
        pageNumber = +getUrlVars()["page"] || 1;

    //next line to trigger infinite scroll to top on scroll (otherwise scroll will not be triggered)
    if(pageNumber > 1 && (window.pageYOffset || document.documentElement.scrollTop)===0) {
        $('body, html').animate({scrollTop: 1}, 1);
    }

    function paginatorOfURL() {
        //change url depending on currently displayed article with page-number in data-page
            $(".js-home-posts article:visible").each(function () {
                if (mostlyVisible(this)) {
                    if (pageNumber !== +$(this).attr("data-page")) {
                        var location = window.location.href.replace(/\/\?page=\d+|\/$/, '');
                        history.replaceState(null, null, +$(this).attr("data-page") === 1 ? location : (location+'/?page=' + $(this).attr("data-page")));
                        pageNumber = +$(this).attr("data-page");
                    }
                }
            });
    }

    function loadUpHome(e) {
        var treshold = 300,
            top = $(window).height() + (window.pageYOffset || document.documentElement.scrollTop),
            footerHeight = $(document).height() - $('div.mw_footer').offset().top,
            postsCount = $('div.home-posts.autoload>article').size(),
            lastPost = $('div.home-posts.autoload>article:last'),
            firstPost = $('div.home-posts.autoload>article:first'),
            postLastTop = (postsCount == 0) ? 0 : (lastPost.offset().top + lastPost.height()),
            navigation = $('.right-navigation');
            //start = $(navigation).data('offset');

        if(e.type && (e.type === "scroll" || e.type === "touchmove") && !homeIsLoading){
            detectScrollDirection();
        }
        //if to load next items || previous items
        if ((postLastTop - treshold < top && !homeIsLoading) || (pageNumber > 1 && e.type && (e.type === "scroll" || e.type === "touchmove") && direction === "up" && (window.pageYOffset || document.documentElement.scrollTop) <= 10 && !homeIsLoading)) {
            if (currentAjaxCount < maximumNewAjax) {
                homeIsLoading = true;
                currentAjaxCount += 1;
                var homeDiv = $('div.home-posts.autoload'),
                    spinner = $('<div class="spinner"><img src="/media/images/ajax-loader.gif"/> Wait please, loading</div>');

                homeDiv.addClass('loading');

                if (direction === "down") {
                    homeDiv.append(spinner);
                } else {
                    homeDiv.prepend(spinner);
                }

                $.ajax({
                        url: '/post/loadPosts?page=' + (pageNumber + (direction ==="up" ? -1 : (direction === "down" ? 1 : 0))),
                        type: "POST",
                        dataType: 'html',
                        data: {
                            parameter: $(navigation).data('parameter'),
                            value: $(navigation).data('value'),
                            blog: homeDiv.data("blog")
                        },
                        success: function (data, textStatus, jqXHR) {
                            var $newData = $(data);
                            if (direction === "down") {
                                homeDiv.append($newData);
                                //when scrolling down we can remove spinner at once
                                homeDiv.removeClass('loading').children('.spinner').remove();
                            } else {
                                $newData.hide();
                                homeDiv.prepend($newData);
                                homeDiv.imagesLoaded(function () {
                                    homeDiv.find('article').show();
                                    homeIsLoading = false;
                                    window.scrollTo(0, $('.js-home-posts article[data-page=' + pageNumber + ']').first().offset().top - 200); // adjust scroll
                                    //when scrolling up we are waiting for all images load and scroll the page before removing the spinner
                                    homeDiv.removeClass('loading').children('.spinner').remove();
                                    //on scrolling up "previous entries" button should appear instantly, if scrolling down - to check not to add two times
                                    if (currentAjaxCount === maximumNewAjax) {
                                        firstPost = $('div.home-posts.autoload>article:first'); //update firstPost
                                        if (+firstPost.attr('data-page') > 1) {
                                            var location = window.location.href.replace(/\/\?page=\d+|\/$/, '');
                                            $(firstPost).before('<h4 style="text-align: center" class="js-previous-entries"><a href="' + location + (+firstPost.attr('data-page') === 2 ? '' : ('/?page=' + (+firstPost.attr('data-page') - 1))) + '" class="to-older-entries" style="color: #c9248f;">PREVIOUS ENTRIES</a></h4>');
                                        }
                                    }

                                });
                            }
                            if ($newData.size() && direction === "down") {
                                homeIsLoading = false;
                            }
                            $s.ignite('div.home-posts', 'Infinite loader');
                            initializeSlider ();
                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            homeDiv.removeClass('loading').children('.spinner').remove();
                            homeDiv.append('<div class="error">Sorry, server error. Refresh the page and try again.</div>');
                        }
                    }
                )
            } else {
                $(window).unbind('scroll resize', loadUpHome);
                if (isMobile) {
                    $('body').unbind('touchmove', loadUpHome); //for iOS
                }
                var location = window.location.href.replace(/\/\?page=\d+|\/$/, '');
                $(lastPost).after('<h4 style="text-align: center"><a href="' + location + '/?page=' + (+lastPost.attr('data-page') + 1) + '" class="to-older-entries" style="color: #c9248f;">OLDER ENTRIES</a></h4>');
                //if previous-entries button wasn't added on scroll up, then add after loading of posts
                if (!$('div.home-posts.autoload .js-previous-entries').length && (+firstPost.attr('data-page') > 1)) {
                    $(firstPost).before('<h4 style="text-align: center"><a href="' + location + (+firstPost.attr('data-page') === 2 ? '' : ('/?page=' + (+firstPost.attr('data-page') - 1))) + '" class="to-older-entries" style="color: #c9248f;">PREVIOUS ENTRIES</a></h4>');
                }
            }
        }
    }

    if ($('div.home-posts.autoload').size()) {
        $(window).bind('update:filters', function () {
            $('div.home-posts.autoload').html("");
            homeIsLoading = false;
            loadUpHome();
        });
        $(window).bind('scroll resize', loadUpHome);
        $(window).bind('scroll resize', paginatorOfURL);
        if (isMobile) {
            $('body').bind('touchmove', loadUpHome); // for iOS
            $('body').bind('touchmove', paginatorOfURL); // for iOS
        }
    }

    function detectScrollDirection() {
        var st = window.pageYOffset;
        if (st > lastScrollTop) {
            direction = 'down';
        } else if (st < lastScrollTop ){
            direction = 'up';
        } else {
            direction = 'static';
        }
        lastScrollTop = st;
        return direction;
    }

    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
            function(m,key,value) {
                vars[key] = value;
            });
        return vars;
    }

    function mostlyVisible(element) {
        // if ca 25% of element is visible
        var scroll_pos = $(window).scrollTop();
        var window_height = $(window).height();
        var el_top = $(element).offset().top;
        var el_height = $(element).height();
        var el_bottom = el_top + el_height;
        return ((el_bottom - el_height*0.25 > scroll_pos) &&
        (el_top < (scroll_pos+0.5*window_height)));
    }
};

/** Inifinite loader for gallery */

$s.behave.onload.inifiniteGallery = function () {

    var galleryIsLoading = false;

    function loadUpgallery() {

        var galleryId = window.location.pathname.indexOf("gallery/view/") >= 0 ? window.location.pathname.substring(window.location.pathname.indexOf("gallery/view/") + "gallery/view/".length) : null,
            treshold = 100,
            top = $(window).height() + (window.pageYOffset || document.documentElement.scrollTop),
            footerHeight = $(document).height() - $('div.mw_footer').offset().top,
            photosCount = $('div.gallery-photos>a').size(),
            container = $('div.gallery-photos'),
            postLastTop = photosCount == 0 ? 0 : (container.offset().top + container.height()),
            data = {offset: photosCount},
            url = galleryId == null ? '/gallery/loadPhotos' : ('/gallery/loadPhotos/' + galleryId);

        if (postLastTop - treshold < top && !galleryIsLoading) {
            galleryIsLoading = true;
            $('div.gallery-photos').addClass('loading').append("<div class='spinner'><img src='/media/images/1-1.gif'/> Wait please, loading</div>");
            $.ajax({
                    url: url,
                    data: _.extend(data, {
                        parameter: $('.right-navigation').data('parameter'),
                        value: $('.right-navigation').data('value')
                    }),
                    dataType: 'html',
                    type: 'POST',

                    success: function (data, textStatus, jqXHR) {
                        var $cont = $('.spiral-masonry');
                        $cont.removeClass('loading').children('.spinner').remove();
                        var $newData = $(data);
                        $cont.append($newData);
                        $cont.masonry('appended', $newData, true);
                        $cont.masonry($cont.data('masonry-options'));
                        $cont.find('.item').addClass('masonry-brick');
                        $s.ignite('div.gallery-photos', 'Infinite loader');

                        if ($newData.size()) {
                            galleryIsLoading = false;
                        }
                        $s.behave.onload.initPinCount();
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        $('div.gallery-photos').removeClass('loading').children('.spinner').remove();
                        $('div.gallery-photos').append('<div class="error">Sorry, server error. Refresh the page and try again.</div>');
                    }
                }
            )
        }
    }


    if ($('div.gallery-photos.autoload').size()) {
        $(window).bind('update:filters', function () {
            var $cont = $('.spiral-masonry');
            $cont.masonry('remove', $cont.find('.item'));
            $cont.empty();
            $cont.masonry();
            $cont.masonry('destroy');
            galleryIsLoading = false;
            loadUpgallery();
        });
        $(window).bind('scroll resize', loadUpgallery);
    }
    var $cont = $('.spiral-masonry');
    $cont.masonry($cont.data('masonry-options'));

    $cont.imagesLoaded(function () {
        $cont.find('.item').addClass('masonry-brick');
    });

//    $cont.each(function () {
//        $cont.masonry($cont.data('masonry-options'));
//    });
};

$s.behave.onload.dropdown = function () {

    $('.dropdown-blog').on("mouseleave", function (e) {
        $(this).removeClass('open');
    });
    $('.dropdown-blog .dropdown-toggle').on("click", function (e) {
        $('.dropdown-blog').addClass("open");
    });

    $('.right-navigation span[data-dropdown]').on("click", function () {
        $('.right-navigation span[data-dropdown]:not(span[data-dropdown="' + $(this).data('dropdown') + '"])').removeClass('open');
        $('.right-navigation span[data-dropdown="' + $(this).data('dropdown') + '"]').toggleClass('open');

        $('.right-navigation div[data-dropdown]:not(div[data-dropdown="' + $(this).data('dropdown') + '"])').addClass('hidden');
        $('.right-navigation div[data-dropdown="' + $(this).data('dropdown') + '"]').toggleClass('hidden');
    });

    $(document).on('click', function(e) {
        if ($(e.target).closest('.right-navigation .dropdown').length || $(e.target).closest('.right-navigation span[data-dropdown]').length) return;
        if($('.right-navigation .dropdown').is(":visible")) {
            $('.right-navigation .dropdown').addClass('hidden');
            $('.right-navigation span[data-dropdown]').removeClass('open');
        }
    });

//    $(".color-piker").on("click", function () {
//        "use strict";
//        $s.showPopup('popupColors');
//        return false;
//    });

    $('.right-navigation ul.letters li:not(.empty)').on("click", function () {
        $('.right-navigation ul.letter').hide();
        $('.right-navigation ul.letter[data-letter="' + $(this).data('letter') + '"]').fadeIn();
    });


    $('.right-navigation ul.letter:first').show();

    $('ul[data-colors]').each(function () {

        var that = $(this),
            subDiv = $(".colors.sub-colors"),
        //group = _.template("<li title='Expand color group' class='color-group' data-group='<%-colorGroup%>'  data-value='<%-color%>'><i style='background-color: <%-htmlColor%>'>&nbsp;</i></li><%=group%>"),
            group = _.template("<li title='Expand color group' class='color-group' data-group='<%-colorGroup%>'  data-value='<%-color%>'><i style='background-color: <%-htmlColor%>'>&nbsp;</i></li>"),
            signle = _.template("<li title='Choose this color' class='color-item' data-group='<%-colorGroup%>' data-value='<%-color%>'><i style='background-color: <%-htmlColor%>'>&nbsp;</i></li>"),
            colors = $(this).data('colors');

        _.each(colors, function (cs, k) {
            var groupC = k.split('x').join(',');
            var data = {
                htmlColor: 'rgb(' + k.split('x').join(',') + ")",
                color: k.split('x').join(','),
                colorGroup: k.split('x').join(','),
                group: _.reduce(cs, function (memo, el) {
//                    return memo+signle({colorGroup: groupC,color: "R"+el[0]+"G"+el[1]+"B"+el[2]+"C", htmlColor: 'rgb('+el.join(',')+')'})
                    return memo + signle({colorGroup: groupC, color: el[0] + "x" + el[1] + "x" + el[2], htmlColor: 'rgb(' + el.join(',') + ')'})
                }, "")
            };
            that.append(group(data));
            subDiv.append(data.group)
        });
    });

    $('.right-navigation li.color-group ').click(function () {
        var group = $(this).closest('li').data('group');
        $('.right-navigation .dropdown[data-dropdown="color"]').addClass('opaque');
        $('.right-navigation li.color-group').addClass('opaque').removeClass('active');
        $('.right-navigation li.color-item').removeClass('active');
        $(this).closest('li').addClass('active').removeClass('opaque').fadeIn();
        $('.right-navigation li.color-item[data-group="' + group + '"]').addClass('active');
    });

    $('.color-popup li.color-group').click(function () {
        var group = $(this).closest('li').data('group');
        $('.color-popup li.color-group').addClass('opaque').removeClass('active');
        $('.color-popup li.color-item').removeClass('active');
        $(this).closest('li').addClass('active').removeClass('opaque').fadeIn();
        $('.color-popup li.color-item[data-group="' + group + '"]').addClass('active');
    });

    /* Select color */
    $('.right-navigation li.color-item').click(function () {
        $('.right-navigation').data('parameter', 'color').data('value', $(this).data('value'));
        var url = '/gallery/color/' + $(this).data('value');
//        if (window.history) {
//            history.pushState({}, 'Title', url);
//            $(window).trigger('update:filters');
//            $('div.dropdown').addClass('hidden');
//
//            $('.gallery-main>h3:first').remove();
//
//            $('.gallery-main').prepend("<h3>Color: " + $(this).html() + "</h3>");
//        } else {
        location.pathname = url;
        document.title = "Indian Wedding Gallery | Maharani Weddings";
//        }

    });

//    var colorPopup = $(".color-popup");
//    $(".color-piker input.color").each(function () {
//        "use strict";
//        var color = $(this).data("value"),
//            group = colorPopup.find("ul.colors.sub-colors li[data-value='" + color + "']").addClass("selected").data("group");
//        colorPopup.find("ul.colors[data-colors] li[data-value='" + group + "']").addClass("has-child");
//    });

    $('.color-popup li.color-item').click(function () {
        $(this).toggleClass("selected");
        var selectedItems = $(this).parents("ul").find("li[data-group='" + $(this).data('group') + "'].selected").size(),
            colorParent = $(".color-popup ul.colors[data-colors] li[data-group='" + $(this).data('group') + "']");
        if (selectedItems > 0) {
            colorParent.addClass("has-child");
        } else {
            colorParent.removeClass("has-child");
        }
    });

//    $('#modal-popupColors').on('hide', function () {
//        var container = $(".color-piker"),
//            tmpl = _.template("<input class='color' name='colors[]' disabled='' style='background-color:rgb(<%-color%>)' data-value='<%-colorGroup%>' value='<%-colorGroup%>'>");
//        container.find("input").remove();
//        $(this).find(".selected").each(function () {
//            "use strict";
//            var color = $(this).data("value"),
//                components = color.split("x");
//            container.append(tmpl({
//                "colorGroup": color,
//                "color": components[0] + "," + components[1] + "," + components[2]
//            }));
//
//        });
//    });

    $('.js-open-search').on('click', function(e){
        e.preventDefault();
        $('.js-open-form').toggleClass('active');
    })

    $('.footer-menu h5').each(function(){
        $(this).on('click', function(){
            $(this).toggleClass('active');
            $(this).closest('section').find('.link-block').slideToggle();
        })
    })

    $('.js-mobile-link').on('click', function(e){
        e.preventDefault()

        $('.js-mobile-menu').toggleClass('active');
    });

    /* Select tag */
    $('.right-navigation ul.letter li').click(function () {
        $('.right-navigation').data('parameter', 'tag').data('value', $(this).data('value'));
        var blog = $(this).parents(".right-navigation").data("blog");
        var prefix = $(this).data('prefix'),
            url = (prefix || '') + blog + '/tag/' + $(this).data('value');
        if (empty(prefix) || prefix === 'home' || !window.history || location.href.indexOf('/photo/') > -1) {
            location.href = url;
        } else {
            document.title = "Indian Wedding Gallery: " + $(this).text() + " | Maharani Weddings";
            history.pushState({}, '', url);
            $(window).trigger('update:filters');
            $('div.dropdown').addClass('hidden');
            $('.right-navigation span').removeClass('open');
            $('.gallery-main>h3:first').remove();
            $('.gallery-main').prepend("<h3>Tag: " + $(this).text() + "</h3>");
        }
    });

    /* Select category */
    $('.right-navigation [data-dropdown="categories"] li').click(function () {
        $('.right-navigation').data('parameter', 'category').data('value', $(this).data('value'));
        var blog = $(this).parents(".right-navigation").data("blog");
        var prefix = $(this).data('prefix'),
            url = (prefix || '') + blog + '/category/' + $(this).data('value');
        if (empty(prefix) || prefix === 'home' || !window.history || location.href.indexOf('/photo/') > -1) {
            location.href = url;
        } else {
            document.title = "Indian Wedding Gallery: " + $(this).text() + " | Maharani Weddings";
            history.pushState({}, '', url);
            $(window).trigger('update:filters');
            $('.right-navigation span').removeClass('open');
            $('div.dropdown').addClass('hidden');
            $('.gallery-main>h3:first').remove();
            $('.gallery-main').prepend("<h3>Category: " + $(this).text() + "</h3>");
        }
    });
};

$(document).on("click", ".confirm-action", function (e) {
    "use strict";
    var message = e.target.getAttribute("data-message");
    if (!window.confirm(message)) {
        e.preventDefault();
    }
});

function attachComment(a, b, c) {
    "use strict";
    $("#comments").find(".comments-wrapper").append(b.comment);
    $(".no-comments-exist").remove();
    $("textarea[name=comment]").val('');
}

$s.behave.onload.social = function () {

    $(document).on("click", ".js-social", function (e) {
        "use strict";
        var that = $(this),
            data = that.parent().data(),
            url = data.share,
            text = data.text,
            service = '';
        url = (url) ? (location.protocol + "//" + location.host + url) : location.href;
        url = encodeURIComponent(url);
        text = (text) ? encodeURIComponent(text) : '';

        if (that.hasClass("share")) {//facebook
            service = "https://www.facebook.com/sharer/sharer.php?u=" + url;
        } else if (that.hasClass("tweet")) {
            service = "https://twitter.com/share?url=" + url + "&text=" + text;
        } else if (that.hasClass("pin")) {
            var pinId = that.data("pinID"),
                postInfo = '';
            if (pinId) {
                postInfo = " Pin: https://pinterest.com/pin/" + pinId + "/";
            }
            var imgURL = that.data("image");
            imgURL = (imgURL.indexOf('http') > -1) ? imgURL : location.protocol + "//" + location.host + imgURL;
            service = "https://pinterest.com/pin/create/button/?url=" + url + "&media=" + encodeURIComponent(imgURL) + "&description=" + text + postInfo;
        } else {
            console.error("unknown service");
            return false;
        }
        window.open(service, 'share-dialog', 'width=626,height=436');
        e.preventDefault();
    });

    $(document).on("click", ".favorite.js-favorite", function (e) {
        "use strict";
        var data = $(this).data(),
            that = $(this);
        $.ajax(
            {
                url: "/account/feed/addToFavorite/" + data.type + "/" + data.id,
                type: 'POST',
                data: "",
                success: function (response) {
                    console.log("AJAX", response);
                    try {
                        var data = JSON.parse(response);
                    } catch (ex) {
                        console.log("AJAX", "Fatal error parsing response", ex, ex.message);
                        return;
                    }
                    console.log(data);
                    if (data.hasOwnProperty("action")) {
                        switch (data.action) {
                            case "authorizationRequired":
                                $s.showPopup('popupSingIn');
                                break;
                            default:
                                console.error("Action is unlnown", data.action);
                        }
                    }
                    if (data.hasOwnProperty("messages")) {
                        if (data.messages.isMyFavorite) {
                            that.addClass("active");
                        } else {
                            that.removeClass("active");
                        }
                        if (that.attr("data-count") && data.messages.favorites) {
                            that.find("span").text(data.messages.favorites);
                        }
                    }
                },
                error: function (xhr, errorType, error) {
                    console.error("Server error");
                }
            });

        e.preventDefault();
    });
};

$s.behave.onload.account = function () {
    window.URL = window.URL || window.webkitURL;//for Safari 6-7 version;
    "use strict";
    $(".click-to-change-photo").on("click", function (e) {
        $(".account-content input[type=file]").trigger("click");
        e.preventDefault();
        e.stopPropagation();
    });
    $(".account-content input[type=file]").on("change", function (e) {
        var photo = $(".account-photo").get(0);
        if (window.URL) {
            photo.style.backgroundImage = "url(" + URL.createObjectURL(this.files[0]) + ")";
        } else if (window.FileReader) {
            var reader = new FileReader();
            reader.addEventListener("load", function (e) {
                photo.style.backgroundImage = "url(" + e.target.result + ")";
            });
            reader.readAsDataURL(this.files[0]);
        }
    });
};

$s.behave.onload.initPinCount = function () {
    "use strict";
    $(".js-social.pin[data-isinit=false]").each(function () {
        $(this).attr("data-isinit", true);
        $.ajax({
            url: 'https://api.pinterest.com/v1/urls/count.json',
            dataType: "jsonp",
            data: "url=" + location.protocol + "//" + location.host + $(this).parent().data("share"),
            context: $(this),
            success: function (data, textStatus) {
                this.find("span").text("(" + data.count + ")");
            }
        });
    });
};

$s.behave.onload.floatingAd = function () {
    "use strict";
    var banners = $('.js-fixed-banner'),
        bannerContainer = $('.sort-block'),
        footer = $('.mw_footer');
    if (banners.length !== 0) {
        $(window).scroll(function (e) {
            var height = banners.outerHeight(true),
                containerHeightNoFixed = bannerContainer.height() - height,
                bottomCorner = containerHeightNoFixed + bannerContainer.offset().top ,
                scroll = $(window).scrollTop(),
                footTop = footer.offset().top;
            if (banners.css('position') == 'fixed') {
                scroll -= height;//
            }
            if (scroll < bottomCorner || bottomCorner + height + 50 > footTop) {
                banners.css({
                    'position': 'static'
                });
            } else {
                var k = (banners.css('position') == 'absolute') ? 1 : 2;
                if (footTop - k * height < scroll) {
                    banners.css({
                        'position': 'absolute',
                        'top': footTop - height - bannerContainer.offset().top
                    });
                } else {
                    banners.css({
                        'position': 'fixed',
                        'top': 0
                    });
                }
            }
        });
    }
};

$s.behave.onload.goTop = function () {
    var check_top_button = {
        button: $("#goTop"),
        visible: false,
        check: function () {
            "use strict";
            var scroll = $(window).scrollTop();
            if (scroll > 150 && !this.visible) {
                this.button.addClass("visible");
                this.visible = true;
            } else if (scroll < 150 && this.visible) {
                this.visible = false;
                $(this).hide();
                this.button.removeClass("visible");
            }
        }
    };

    $(window).on("resize",function () {
        "use strict";
        check_top_button.check();
    }).on("scroll", function (e) {
            "use strict";
            check_top_button.check();
        });

    $("#goTop").on("click", function () {
        "use strict";
        $('body').animate({ scrollTop: 0 }, 400);
        $('html').animate({ scrollTop: 0 }, 400);

    });

    check_top_button.check();
};

$s.behave.onload.toggleEditor = function () {
    "use strict";
    $("#toggleEditor").on("change", function () {
        if ($(this).prop("checked")) {
            tinymce.get("editor").show();
        } else {
            tinymce.get("editor").hide();
        }
    });
};

$s.behave.onload.clickOnHover = function () {
    "use strict";
    $(".click-on-hover").on("mouseover", function (e) {
        $(this).trigger("click");
        e.preventDefault();
    });
    $(".click-on-hover").on("click", function (e) {
        if ($(this).hasClass('js-disabled')) return false;
    });

};

$s.behave.onload.signDropDown = function () {
    "use strict";
    $(".account-pull .dropdown").on("mouseleave", function (e) {
        $('.dropdown.open .dropdown-toggle').dropdown('toggle');
    });
    $('.account-pull .dropdown-menu, .account-pull .dropdown input, .account-pull .dropdown label').on("click", function (e) {
        e.stopPropagation();
    });
};

$s.behave.onload.switchPopups = function () {
    "use strict";
    $(document).on("click", ".switch-popups", function (e) {

        var isModal = !!($(e.currentTarget).parents(".modal").length),
            form = $(e.currentTarget).parents("form"),
            login = form.find("input[name=email]").val(),
            password = form.find("input[name=password]").val();
        if (isModal) {
            var otherModal = $("#modal-popupSingUp");
            $("#modal-popupSingIn").modal('hide');
            otherModal.modal('show');
            if (login.indexOf("@") !== -1) { //if email
                otherModal.find("input[name=email]").val(login);
            } else {
                otherModal.find("input[name=username]").val(login);
            }
            otherModal.find('.alert').remove();
            otherModal.find('.field').removeClass("invalid error warning success info");
            otherModal.find("input[name=password]").val(password);
            e.preventDefault();
        }


    })
};

$s.behave.onload.changeLanguage = function () {
    "use strict";

    var translateSelector = $("select.goog-te-combo").get(0);
    //http://stackoverflow.com/questions/6303021/trigger-google-web-translate-element
    function fireEvent(element, event) {

        if (document.createEventObject) {
            // dispatch for IE

            var evt = document.createEventObject();
            return element.fireEvent('on' + event, evt)
        }
        else {
            // dispatch for firefox + others

            var evt = document.createEvent("HTMLEvents");
            evt.initEvent(event, true, true); // event type,bubbling,cancelable
            return !element.dispatchEvent(evt);
        }
    }

    $(".lng a").on("click", function (e) {
        e.preventDefault();
        if (translateSelector === undefined) {
            translateSelector = $("select.goog-te-combo").get(0);
            if (translateSelector === undefined) {
                return false;
            }

        }
        translateSelector.value = $(this).data("lng");
        fireEvent(translateSelector, "change");
    })


};

$s.behave.onload.showAds = function () {
    "use strict";
    $(".banners-wrapper").css({"display": "block"});
};

/**
 * Changing available list of countries/states due to selected location
 */
$s.behave.onload.changeLocation = function () {
    $('.locationRadio').on('click', function () {
        var location = $(this).val();
        var states = $.parseJSON($("#alter").attr('data-states'));
        var select = $('#states');
        select.children().remove();
        for (var i in states[location]) {
            $("<option></option>", {value: i, text: states[location][i]}).appendTo(select);
        }
        $s.selectBox = new $s.Behavior("selectbox");
        select.selectBox('refresh');
    });
};

$s.behave.onload.ellipsizeTextBox = function () {
    function EllipsizedText (node) {
        var instance = this;
        this.initialized = false;

        this.init = function (el) {
            this.el = el;
            this.textBlock = el.querySelector('.js-text-ellipsis');
            this.readMoreBtn = el.querySelector('.js-read-more');

            this.visibleElementsLength = el.dataset.elements ? +el.dataset.elements : null;
            this.collapsedHeight = null;
            this.tagName = el.dataset.tag || 'p';
            this.transitionDuration = el.dataset.transition || 300;

            if (this.visibleElementsLength) {
                this.allElements = this.splitByTag(this.textBlock.innerHTML);
                if (this.allElements.length && this.allElements.length <= this.visibleElementsLength) {
                    this.el.style.maxHeight = 'none';
                    this.el.classList.add('initialized');
                    return;
                }
                if (this.allElements.length > this.visibleElementsLength) {
                    this.el.classList.add('limit-by-tag');
                    this.collapsedHeight = this.getCollapsedHeight();
                    this.el.style.transition = 'height ' + this.transitionDuration + 'ms'
                }
                if (!this.allElements.length) { // text is not splitted by tag
                    this.el.style.transition = 'max-height ' + this.transitionDuration + 'ms'
                }
            } else {
                this.el.style.transition = 'max-height ' + this.transitionDuration + 'ms'
            }

            this.ellipsizeTextBox();

            this.readMoreBtn.addEventListener('click', function (e) {
                e.preventDefault();
                instance.toggleTextCollapse(e);
            });
            window.addEventListener('resize', this.ellipsizeTextBox.bind(this, true));
            window.addEventListener('resize', this.setCollapsedHeight.bind(this));
        }

        this.setCollapsedHeight = function () {
            this.collapsedHeight = this.getCollapsedHeight();
        };

        this.toggleTextCollapse = function () {
            this.el.classList.toggle('collapsed');
            this.ellipsizeTextBox();
        };

        this.ellipsizeTextBox = function(resize) {
            if (this.el.classList.contains('ellipsized') && (!instance.visibleElementsLength || !instance.allElements.length)) {
                this.textBlock.innerHTML = this.textBlock.getAttribute('data-text')
            }
            setTimeout(function () {
                if (instance.visibleElementsLength && instance.allElements.length) {
                    if (resize) {
                        if (instance.el.classList.contains('ellipsized')) {
                            instance.el.style.height = instance.collapsedHeight + 'px';
                        } else {
                            instance.el.style.height = instance.textBlock.offsetHeight + 'px';
                        }
                    } else {
                        if (instance.el.classList.contains('ellipsized')) {
                            instance.el.classList.remove('ellipsized');
                            instance.el.style.height = instance.el.scrollHeight - instance.readMoreBtn.offsetHeight + 'px';
                        } else {
                            instance.el.classList.add('ellipsized');
                            instance.el.style.height = instance.collapsedHeight + 'px';
                        }
                    }
                } else {
                    if (instance.el.scrollHeight > instance.el.offsetHeight) {
                        instance.el.classList.add('ellipsized');
                        instance.textBlock.setAttribute('data-text', instance.textBlock.innerText);
                        var letterArray = instance.textBlock.innerText.split('');

                        while (instance.el.scrollHeight > instance.el.offsetHeight) {
                            letterArray.pop();
                            instance.textBlock.innerText = letterArray.join('') + '...';
                        }

                    } else {
                        instance.el.classList.remove('ellipsized');
                    }
                }
                if (!instance.initialized) {
                    instance.el.classList.add('initialized');
                    instance.initialized = true;
                }
            }, resize ? 0 : this.transitionDuration);
        };

        this.splitByTag = function (html) {
            var div = document.createElement("div");
            div.innerHTML = html;
            return [].filter.call(div.childNodes, function(node) {
                return node.nodeName === instance.tagName.toUpperCase();
            });
        }

        this.getCollapsedHeight = function () {
            var el = this.textBlock.getElementsByTagName(this.tagName)[this.visibleElementsLength - 1];
            return el ? el.offsetTop + el.offsetHeight : null;
        }

        this.init(node);
    }

    [].forEach.call(document.querySelectorAll('.js-read-more-block'), function (el) {
        new EllipsizedText(el)
    })
}

var loginForm;

function FBLogin(e) {
    e.preventDefault();
    loginForm = $(e.target).closest('form');
    if (FB) {
        FB.login(FBLoginCallback, {
            scope: 'email,public_profile',
            return_scopes: true
        });
    } else {
        console.warn('Facebook is not initialized yet');
    }
}

function FBLoginCallback(response) {
    afterFBLogin(response, redirectTo);
}

function afterFBLogin(response, redirectURL) {
    // Here we specify what we do with the response anytime this event occurs.
    if (!response || !response.authResponse) return;

    if (response.authResponse.grantedScopes && response.authResponse.grantedScopes.indexOf("email") === -1) {
        FB.login(FBLoginCallback, {
            scope: 'email',
            return_scopes: true,
            auth_type: "rerequest"
        });
    } else {
        if (response.status === 'connected') {
            FB.api('/me', function (userData) {
                $.ajax({
                    url: '/account/facebook',
                    data: {
                        fbAccessToken: response.authResponse.accessToken,
                        userData: userData
                    },
                    type: 'POST',
                    success: function (ans) {
                        var response = JSON.parse(ans);
                        if (response.status === 'success') {
                            if (redirectURL) {
                                location.href = redirectURL;
                            } else {
                                location.reload();
                            }
                        } else if (response.suggestion) {
                            $s.showPopup("popupLinking");
                            $('.js-linking-form').find("input[name=email]").val(response.facebookData.email);
                            $('.js-linking-form').append("<input type='hidden' name='facebookUID' value='"+ response.facebookData.id +"'/>");
                        } else if (response['complete-form']) {
                            $s.showPopup("popupSingUp");
                            loginForm = $('#modal-popupSingUp').find('form');
                            loginForm.find("input[name=email]").val(response.facebookData.email);
                            loginForm.append("<input type='hidden' name='facebookUID' value='"+ response.facebookData.id +"'/>");
                            loginForm.find('.js-facebook-btn').addClass('hide');
                            if (response.messages) {
                                for (var message in response.messages) {
                                    var firmField = loginForm.find("input[name=" + message + "]");
                                    firmField.after('<div class="alert alert-field alert-error"><div>' + response.messages[message] + '</div></div>');
                                    firmField.closest('.control-group').addClass('error');
                                }
                            }
                        } else {
                            if (loginForm.closest('.dropdown').length) loginForm.closest('.dropdown').addClass('open');
                            if (response.facebookData) {
                                loginForm.find("input[name=email]").val(response.facebookData.email);
                                loginForm.find("input[name=username]").val(response.facebookData.name);
                            }

                            loginForm.find('fieldset').append('<div class="alert alert-field alert-error" style="margin-top: 20px;"><div>' + response.message+ '</div></div>');

                            if (response.messages) {
                                for (var message in response.messages) {
                                    var firmField = loginForm.find("input[name=" + message + "]");
                                    firmField.after('<div class="alert alert-field alert-error"><div>' + response.messages[message] + '</div></div>');
                                    firmField.closest('.control-group').addClass('error');
                                }
                            }
                        }
                    }
                });
            });
            // Logged into your app and Facebook.
        } else if (response.status === 'not_authorized') {
            // The person is logged into Facebook, but not your app.
        } else {
            // The person is not logged into Facebook, so we're not sure if they are logged into this app or not.
        }
    }
}

function initializeSlider() {
    var $els = $('.js-slideshow-post:not(.initialized)');
    $els.each(function(){
        var that = this;
        $(this).fotorama({});
        $(this).addClass('initialized');
        $(this).on('fotorama:load fotorama:show fotorama:fullscreenenter fotorama:fullscreenexit', function (e, fotorama) {
            fotorama.resize();
            var block = $(this).closest('.js-post-content');
            block.find('.js-photo-description').hide();
            block.find('.js-photo-description[data-slide=' + (fotorama.activeIndex + 1) + ']').show();
            $(this).siblings('.js-slideshow-info').text((fotorama.activeIndex + 1) + ' of ' + fotorama.size);
        });
        $(this).on('fotorama:fullscreenexit', function(){
            $(that).data('fotorama').show('>').show('<');
        })
    });
}

$(function() {
    initializeSlider();
    $(document).on('click', '.js-slideshow-fullscreen', function(){
        $(this).siblings('.js-slideshow-post').data('fotorama').requestFullScreen();
    });
    $(document).on('click', '.js-slideshow-prev', function(){
        $(this).siblings('.js-slideshow-post').data('fotorama').show('<');
    });
    $(document).on('click', '.js-slideshow-next', function(){
        $(this).siblings('.js-slideshow-post').data('fotorama').show('>');
    })
});

//landing page
$(function(){
  $('.js-toggle-posts').on('click', function(){
    $(this).toggleClass('more-label');
    $('.js-post-collapsible').toggle();
  });
  $('.js-toggle-favorite-things').on('click', function(){
    $(this).toggleClass('more-label');
    $('.js-thing-collapsible').toggle();
  })
});
